// @ts-nocheck
/* eslint-disable */

'use client';

import React, {useEffect} from 'react';
import {Button} from '@modules/Core/components/base/buttons/Button';
import {Section} from '@modules/Core/components/layout/Section';
import {dispatchReactEvent} from '@modules/Core/util/eventsUtil';
import {trans} from '@modules/Translations/util/i18n';

const NotFound: React.FC = () => {
  useEffect(() => {
    dispatchReactEvent('navigate.done');
  }, []);

  return (
    <Section
      title={trans('error.not_found_title')}
      description={trans('error.not_found_message')}
      descriptionVariant="body1"
      image="/assets/img/design_images/not_found.png"
      imageViewDirection="vertical"
      justifyContent="center"
    >
      <Button width="relative-col-width-2" link={{href: '/'}}>
        {trans('error.not_found_button')}
      </Button>
    </Section>
  );
};

export default NotFound;
